exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-aquatic-resource-js": () => import("./../../../src/pages/services/aquatic-resource.js" /* webpackChunkName: "component---src-pages-services-aquatic-resource-js" */),
  "component---src-pages-services-environmental-impact-js": () => import("./../../../src/pages/services/environmental-impact.js" /* webpackChunkName: "component---src-pages-services-environmental-impact-js" */),
  "component---src-pages-services-environmental-monitoring-js": () => import("./../../../src/pages/services/environmental-monitoring.js" /* webpackChunkName: "component---src-pages-services-environmental-monitoring-js" */),
  "component---src-pages-services-environmental-protection-js": () => import("./../../../src/pages/services/environmental-protection.js" /* webpackChunkName: "component---src-pages-services-environmental-protection-js" */),
  "component---src-pages-services-mapping-js": () => import("./../../../src/pages/services/mapping.js" /* webpackChunkName: "component---src-pages-services-mapping-js" */),
  "component---src-pages-services-regulatory-applications-js": () => import("./../../../src/pages/services/regulatory-applications.js" /* webpackChunkName: "component---src-pages-services-regulatory-applications-js" */),
  "component---src-pages-services-site-liaison-js": () => import("./../../../src/pages/services/site-liaison.js" /* webpackChunkName: "component---src-pages-services-site-liaison-js" */),
  "component---src-pages-services-wildlife-inventory-js": () => import("./../../../src/pages/services/wildlife-inventory.js" /* webpackChunkName: "component---src-pages-services-wildlife-inventory-js" */)
}

